import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import edSheeran from "../images/404.gif"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="white-wrapper">
        <div className="default-container">
          <img
            src={edSheeran}
            alt="Confused Travolta"
            className={"full-width-404"}
          />
          <h1>404 - NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
